var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c(
            "Select",
            {
              attrs: {
                size: _vm.size,
                loading: _vm.loading,
                placeholder: _vm.placeholder,
                multiple: _vm.multiple,
                disabled: _vm.disabled,
                filterable: "",
                transfer: _vm.transfer,
                clearable: _vm.clearable,
                placement: _vm.placement,
                "not-found-text": _vm.notFoundText,
                "label-in-value": _vm.labelInValue,
                "transfer-class-name": _vm.transferClassName,
                prefix: _vm.prefix,
                "max-tag-count": _vm.maxTagCount,
                "max-tag-placeholder": _vm.maxTagPlaceholder,
                "remote-method": _vm.searchUser,
              },
              on: {
                "on-change": _vm.handleChange,
                "on-query-change": _vm.handleQueryChange,
                "on-clear": _vm.handleClear,
                "on-open-change": _vm.handleOpenChange,
                "on-select": _vm.handleSelect,
              },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            _vm._l(_vm.userList, function (item, i) {
              return _c(
                "Option",
                {
                  key: i,
                  attrs: {
                    value: item.id,
                    label: item.nickname,
                    tag: item.username,
                  },
                },
                [
                  _c("span", { staticStyle: { "margin-right": "10px" } }, [
                    _vm._v(_vm._s(item.nickname)),
                  ]),
                  _c("span", { staticStyle: { color: "#ccc" } }, [
                    _vm._v(_vm._s(item.username)),
                  ]),
                ]
              )
            }),
            1
          ),
          _vm.showButton
            ? _c(
                "Button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    size: _vm.size,
                    disabled: _vm.disabled,
                    icon: _vm.icon,
                    type: _vm.type,
                    shape: _vm.shape,
                    ghost: _vm.ghost,
                  },
                  on: {
                    click: function ($event) {
                      _vm.userModalVisible = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.text))]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showButton
        ? _c(
            "Drawer",
            {
              staticClass: "user-select-drawer",
              attrs: {
                title: "选择用户",
                closable: "",
                width: "815",
                draggable: "",
                transfer: _vm.drawerTransfer,
                "mask-style": _vm.maskStyle,
                "class-name": _vm.className,
              },
              model: {
                value: _vm.userModalVisible,
                callback: function ($$v) {
                  _vm.userModalVisible = $$v
                },
                expression: "userModalVisible",
              },
            },
            [
              _c(
                "Form",
                {
                  ref: "searchForm",
                  attrs: {
                    model: _vm.searchForm,
                    inline: "",
                    "label-width": 70,
                  },
                },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "用户名", prop: "nickname" } },
                    [
                      _c("Input", {
                        staticStyle: { width: "180px" },
                        attrs: {
                          type: "text",
                          clearable: "",
                          placeholder: "请输入用户名",
                        },
                        model: {
                          value: _vm.searchForm.nickname,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "nickname", $$v)
                          },
                          expression: "searchForm.nickname",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "FormItem",
                    { attrs: { label: "部门", prop: "department" } },
                    [
                      _c("department-choose", {
                        ref: "dep",
                        staticStyle: { width: "180px" },
                        on: { "on-change": _vm.handleSelectDep },
                      }),
                    ],
                    1
                  ),
                  _vm.drop
                    ? _c(
                        "span",
                        [
                          _c(
                            "FormItem",
                            { attrs: { label: "手机号", prop: "mobile" } },
                            [
                              _c("Input", {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  type: "text",
                                  clearable: "",
                                  placeholder: "请输入手机号",
                                },
                                model: {
                                  value: _vm.searchForm.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "mobile", $$v)
                                  },
                                  expression: "searchForm.mobile",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "FormItem",
                            { attrs: { label: "邮箱", prop: "email" } },
                            [
                              _c("Input", {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  type: "text",
                                  clearable: "",
                                  placeholder: "请输入邮箱",
                                },
                                model: {
                                  value: _vm.searchForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "email", $$v)
                                  },
                                  expression: "searchForm.email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "FormItem",
                            { attrs: { label: "性别", prop: "sex" } },
                            [
                              _c("dict", {
                                staticStyle: { width: "180px" },
                                attrs: { dict: "sex" },
                                model: {
                                  value: _vm.searchForm.sex,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "sex", $$v)
                                  },
                                  expression: "searchForm.sex",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "FormItem",
                            { attrs: { label: "登录账号", prop: "username" } },
                            [
                              _c("Input", {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  type: "text",
                                  clearable: "",
                                  placeholder: "请输入登录账号",
                                },
                                model: {
                                  value: _vm.searchForm.username,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "username", $$v)
                                  },
                                  expression: "searchForm.username",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "FormItem",
                            { attrs: { label: "用户ID", prop: "id" } },
                            [
                              _c("Input", {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  type: "text",
                                  clearable: "",
                                  placeholder: "请输入用户ID",
                                },
                                model: {
                                  value: _vm.searchForm.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "id", $$v)
                                  },
                                  expression: "searchForm.id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "FormItem",
                    {
                      staticClass: "br",
                      staticStyle: { "margin-left": "-35px" },
                    },
                    [
                      _c(
                        "Button",
                        {
                          attrs: { type: "primary", icon: "ios-search" },
                          on: { click: _vm.handleSearchUser },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c("Button", { on: { click: _vm.handleResetUser } }, [
                        _vm._v("重置"),
                      ]),
                      _c(
                        "a",
                        {
                          staticClass: "drop-down",
                          on: { click: _vm.dropDown },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.dropDownContent) + " "),
                          _c("Icon", { attrs: { type: _vm.dropDownIcon } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("Alert", { attrs: { "show-icon": "" } }, [
                _vm._v(" 已选择 "),
                _c("span", { staticClass: "select-count" }, [
                  _vm._v(_vm._s(_vm.selectCount)),
                ]),
                _vm._v(" 项 "),
                _c(
                  "a",
                  {
                    staticStyle: { "margin-left": "10px" },
                    on: { click: _vm.clearData },
                  },
                  [_vm._v("清空已选")]
                ),
              ]),
              _c("Table", {
                staticStyle: { margin: "2vh 0" },
                attrs: {
                  loading: _vm.userLoading,
                  border: "",
                  columns: _vm.userColumns,
                  data: _vm.userData,
                },
              }),
              _c(
                "Row",
                { attrs: { type: "flex", justify: "end" } },
                [
                  _c("Page", {
                    attrs: {
                      current: _vm.searchForm.pageNumber,
                      total: _vm.totalUser,
                      "page-size": _vm.searchForm.pageSize,
                      "page-size-opts": [10, 20, 50],
                      size: "small",
                      "show-total": "",
                      "show-elevator": "",
                      "show-sizer": "",
                      transfer: true,
                    },
                    on: {
                      "on-change": _vm.changePage,
                      "on-page-size-change": _vm.changePageSize,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }